<template>
  <div class="modal fade show" data-backdrop="static" v-if="modalVisible">
    <div class='modal__backdrop'></div>
    <div class="modal-dialog modal-dialog-centered" style="z-index: 99999" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("merchant.btn_approve_modal") }}
          </h5>
          <button type="button" class="close" @click="closeModal()">
            <i aria-hidden="true" class="ki ki-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="* * * * * *"
              v-model="financeModalActionForm.sms"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal()">
            {{ $t("merchant.btn_close") }}
          </button>
          <button type="button" class="btn btn-primary" @click.prevent="handleSubmit()">
            {{ $t("password_change.confirm") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  data() {
    return {
      loading: true,
    };
  },
  components: {},
  computed: {
    ...mapState({
      modalVisible: (state) => state.withdrawTransaction.smsFinanceModalVisible,
      financeModalActionForm: (state) => state.withdrawTransaction.financeModalActionForm,
    }),
  },
  methods: {
    closeModal() {
      this.$store.commit("withdrawTransaction/TOGGLE_SMS_FINANCE_MODAL", false);
    },
    handleSubmit() {
      if(!this.financeModalActionForm.sms) {
        return
      }
      this.$store.dispatch('withdrawTransaction/FINANCE_ACTION')
        .then(() => {
            this.$emit('withdrawSmsFinanceSentSuccess')
        })
        .catch(() => {})
    },
  },
  created() {},
};
</script>
<style scoped>
.show {
  display: block;
}
.modal__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}
</style>
