<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("f_management.fait_transactions") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <datatable-dropdown />
            </div>
          </div>
          <div class="card-body">
            <Datatable
              ref="datatable"
              :url="datatableUrl"
              :dataUrl="datatableDataUrl"
              @data="loadDatatableData($event)"
            >
              <!-- <template v-slot:head>
                              Table head özelleştirme
                          </template> -->
              <template v-slot:body>
                <tr v-for="(item, itemIndex) in datatableData" :key="itemIndex">
                  <th scope="row">{{ item.id }}</th>
                  <td>
                    {{ item.code }}
                    <!-- <router-link
                                      :to="`/finance/withdraw/${item.id}`"
                                      class="text-primary font-weight-bold"
                                      >
                                      {{ item.code }}
                                      </router-link> -->
                  </td>
                  <td>
                    <router-link
                      :to="`/users/${item.user_id}/overview`"
                      class="text-primary font-weight-bold"
                    >
                      {{ item.full_name }}
                    </router-link>
                  </td>
                  <td>{{ item.bank }}</td>
                  <td>{{ item.currency }}</td>
                  <td>{{ item.amount_formatted }}</td>
                  <td>
                    <span class="badge" :class="statusClass(item.status)">
                      {{ statusLabel(item.status) }}
                    </span>
                  </td>
                  <td v-html="item.created_at"></td>
                  <td v-html="item.completed_at"></td>
                  <td>
                    <!-- <div class="d-flex flex-shrink-0">
                                          <button
                                              @click="showDetailsModal(item.id)"
                                              v-if="checkPermission('crm.finance.withdraw.modal')"
                                              class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1"
                                              :title="$t('commons.details')"
                                          >
                                              <i class="flaticon2-document"></i>
                                          </button>
                                          <button
                                              @click="showPastTransactionsModal(item.id)"
                                              v-if="checkPermission('crm.finance.withdraw.history')"
                                              class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1"
                                              :title="$t('employees.past_transactions')"
                                          >
                                              <i class="fa fa-history"></i>
                                          </button>
                                        
                                          <button @click="updateStatus(item.id, '10')"
                                              v-if="checkPermission('crm.finance.withdraw.update-status') && +item.status !== 10"
                                              class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1"
                                              :title="$t('f_management.re_approve')"
                                          >
                                              <i class="fa fa-backward"></i>
                                          </button>
                                          
                                          <button
                                              @click="updateStatus(item.id, '40')"
                                              v-if="checkPermission('crm.finance.withdraw.cancel') && +item.status !== 40"
                                              class="btn btn-icon btn-hover-icon-dark btn-clean btn-bg-light btn-sm mr-1"
                                              :title="$t('f_management.cancel_transaction')"
                                          >
                                              <i class="fa fa-ban text-danger"></i>
                                          </button>
                                      </div> -->
                  </td>
                </tr>
              </template>
            </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->

    <DetailsModal />

    <SmsModal @withdrawSmsSentSuccess="initDatatable" />
    <SmsFinanceModal @withdrawSmsFinanceSentSuccess="initDatatable" />

    <AuditModal
      v-if="audit_modal_visible"
      ref="auditModal"
      :close="
        () => $store.commit('withdrawTransaction/TOGGLE_AUDIT_MODAL', false)
      "
    >
      <template v-slot:header>
        <h1>{{ $t("employees.audit_modal_title") }}</h1>
      </template>
      <template v-slot:body>
        <table
          class="table table-bordered collapsed table-head-bg"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th scope="col">
                {{ $t("employees.audit_user") }}
              </th>
              <th scope="col">
                {{ $t("employees.audit_area") }}
              </th>
              <th scope="col">
                {{ $t("employees.audit_old_value") }}
              </th>
              <th scope="col">
                {{ $t("employees.audit_new_value") }}
              </th>
              <th scope="col">
                {{ $t("employees.audit_date") }}
              </th>
            </tr>
          </thead>
          <tbody v-for="(auditItem, index) in audits" :key="'audit-' + index">
            <tr
              v-for="(newValKey, newValKeyIndex) in Object.keys(
                auditItem.new_values
              )"
              :key="'newValKeyIndex-' + newValKeyIndex"
            >
              <td scope="row">
                <span class="truncateText">
                  <template v-if="auditItem.user">
                    <a
                      href="javascript:;"
                      @click="goToUserDetails(auditItem.user.id)"
                    >
                      {{ auditItem.user["name"] }}
                      {{ auditItem.user["surname"] }}
                    </a>
                  </template>
                </span>
              </td>
              <td scope="row">
                <span class="truncateText">{{ newValKey }}</span>
              </td>
              <td scope="row">
                <span class="truncateText">{{
                  auditItem.old_values[newValKey] || ""
                }}</span>
              </td>
              <td scope="row">
                <span class="truncateText">{{
                  auditItem.new_values[newValKey] || ""
                }}</span>
              </td>
              <td scope="row">
                <span class="truncateText">{{ auditItem.updated_at }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </AuditModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import DatatableDropdown from "@/components/datatable/Dropdown.vue";
import "bootstrap-daterangepicker";
import Swal from "sweetalert2";
import AuditModal from "@/components/custom/AuditModal.vue";
import DetailsModal from "@/components/financialManagement/withdraw/DetailsModal.vue";
import SmsModal from "@/components/financialManagement/withdraw/SmsModal.vue";
import SmsFinanceModal from "@/components/financialManagement/withdraw/SmsFinanceModal.vue";
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";
import $ from "jquery";

export default {
  name: "WithdrawTransactions",
  data() {
    return {
      isLoading: true,
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    };
  },
  components: {
    Breadcrumb,
    DatatableDropdown,
    AuditModal,
    DetailsModal,
    SmsModal,
    SmsFinanceModal,
    Datatable,
  },
  computed: {
    ...mapState({
      withdraw_data: (state) => state.withdrawTransaction.withdraw_data,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
      audits: (state) => state.withdrawTransaction.audits,
      audit_modal_visible: (state) =>
        state.withdrawTransaction.audit_modal_visible,
    }),
    breadcrumb_data() {
      return {
        title: this.$t("f_management.title"),
        subtitle: [this.$t("f_management.withdraw_transactions")],
      };
    },
  },
  methods: {
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    checkPermission(permission) {
      return this.permissions?.some((val) => val.name == permission);
    },
    getTimeForParsedDate(rawDate) {
      const dateArray = rawDate.split("/"); // "18/02/2022"(DD/MM/YYYY) - convert to "2022-02-18"(YYYY-MM-DD)
      const parsedDate = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
      return new Date(parsedDate).getTime();
    },
    showPastTransactionsModal(id) {
      this.$store.dispatch("withdrawTransaction/GET_AUDITS", id);
    },
    goToUserDetails(user_id) {
      this.$store.commit("withdrawTransaction/TOGGLE_AUDIT_MODAL", false);
      this.$router.push({ path: `/users/${user_id}` });
    },
    showDetailsModal(id) {
      this.$store.dispatch("withdrawTransaction/GET_DETAILS_MODAL", id);
      this.$store.commit("withdrawTransaction/TOGGLE_DETAILS_MODAL", true);
    },
    statusClass(status) {
      switch (status) {
        case 10:
        case 90:
          return "badge-warning";
        case 20:
          return "badge-light";
        case 30:
          return "badge-success";
        case 40:
        case 50:
        case 60:
        case 70:
        case 80:
          return "badge-danger";
        default:
          return "";
      }
    },
    statusLabel(status) {
      if (status) {
        return this.$t(`f_management.statuses.${status}`);
      }
      return "-";
    },
    updateStatus(id, status) {
      const vm = this;
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("commons.approve"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then(function (result) {
        if (result.value) {
          vm.$store
            .dispatch("withdrawTransaction/UPDATE_STATUS", {
              id,
              status,
            })
            .then(() => {
              vm.initDatatable();
            })
            .catch(() => {});
        }
      });
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    },
  },
  created() {
    if (
      this.permissions?.length > 0 &&
      !this.checkPermission("crm.finance.withdraw.index")
    ) {
      this.$router.push("/no-permission");
    }

    this.datatableUrl = api_old.withdraw_datatable;
    this.datatableDataUrl = api_old.fait_datatable_data;
    if (this.$route.query?.user) {
      this.datatableDataUrl = `${api_old.fait_datatable_data}?user_id=${this.$route.query.user}`;
    }

    const self = this;
    self.$nextTick(function () {
      $("#printExportItem").hide();
      $("#copyExportItem").hide();
      $("#excelExport").on("click", function (e) {
        e.preventDefault();
        let data = {
          url: `financecrm/withdraw/export`,
          label: `withdraw-transactions.xlsx`,
          reqPayload: self.$refs.datatable.selectedFilters,
        };
        self.$store.dispatch("global/GET_EXCEL", data);
      });
      $("#csvExportItem").hide();
      $("#pdfExportItem").hide();
    });
  },
  watch: {
    $route: function (val) {
      if (!val.query?.user) {
        this.datatableDataUrl = api_old.fait_datatable_data;
      } else {
        this.datatableDataUrl = `${api_old.fait_datatable_data}?user_id=${val.query.user}`;
      }
    },
  },
};
</script>
<style lang="scss"></style>
